.logo {
  width: 160px;
  float: right;
}

h1 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1.5rem;
}

h2 {
  font-weight: normal;
  font-size: 1.2rem;
}

table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}

td {
  padding: 4px;
  border: solid 1px #dee2e6;
}

td.MuiTableCell-footer {
  font-size: 1rem;
}

.right {
  text-align: right;
}
